@import '../../sass/global.scss';

.homescreen {
	background-color: $c-teal-dark;
	padding: $gutter-s 0;
	color: $c-white;

	@media #{$mq-l} {
		display: none;
	}

	.homescreen__wrap {
		grid-gap: $gutter-s;
	}

	.homescreen__image {
		grid-column: 1 / 4;

		@media #{$mq-m} {
			grid-column: 1 / 2;
		}

		img {
			box-shadow: 0px 0px 20px rgba($c-black, 0.2);
			display: block;
			border-radius: 12px;
			width: 100%;
			height: auto;

			@media #{$mq-m} {
				border-radius: 8px;
			}
		}
	}

	.homescreen__copy {
		grid-column: 4 / -1;

		@media #{$mq-m} {
			grid-column: 2 / -1;
			display: grid;
			grid-template-columns: 1fr 3fr;
			grid-gap: $gutter-s;
		}
	}

	.homescreen__title {
		margin: 0;
		color: $c-white;
	}

	.homescreen__bar {
		display: none;

		@supports (-webkit-overflow-scrolling: touch) {
			background-color: $c-white;
			box-shadow: 0px 0px 20px rgba($c-black, 0.2);
			display: grid;
			grid-template-columns: 3fr 1fr;
			border-radius: 8px;
			grid-column: 1 / -1;
			padding: 10px 15px;
			color: $c-black;
		}

		@media #{$mq-m} {
			display: none;
		}

		.bar__icon {
			text-align: right;

			span {
				border: 1px $c-black solid;
				border-radius: 4px;
				display: inline-block;
				vertical-align: middle;
				font-weight: 500;
				font-size: 1.2rem;
				line-height: 1.2rem;
				text-align: center;
				padding: 2px 0 0 0;
				width: 20px;
				height: 20px;
			}
		}
	}

	.homescreen__android {
		grid-column: 1 / -1;

		// ios
		@supports (-webkit-overflow-scrolling: touch) {
			display: none;
		}

		@media #{$mq-m} {
			display: none;
		}

		img {
			box-shadow: 0px 0px 20px rgba($c-black, 0.2);
			border-radius: 2px;
			display: block;
			width: 100%;
		}
	}
}
