@import '../../sass/global.scss';

.header {
	background-color: $c-teal;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $gutter-s-half;
	width: 100%;

	@media #{$mq-m} {
		padding: $gutter-s;
	}

	.header__logo {

		.logo__a {
			display: flex;
			align-items: center;
		}

		.logo__title {
			margin: 0;
			color: $c-white;
		}

		svg {
			margin: 0 10px 0 0;
			height: 30px;
		}
	}

	.header__nav {

		ul {
			display: flex;
			align-items: center;
			margin: 0;
			padding: 0;

			li {
				margin: 0 0 0 $gutter-s;
				list-style: none;

				&:first-child {
					margin: 0;
				}
			}

			a {
				border: 2px $c-white solid;
				border-radius: 4px;
				padding: 10px;
				display: flex;
				align-items: center;
				font-weight: 700;
				font-size: 1.2rem;
				line-height: 1.2rem;
				color: $c-white;

				@media #{$mq-l} {
					background-color: transparent;
				}

				svg {
					margin: 0 5px 0 0;
					height: 18px;
				}

				path {
					fill: $c-white;
				}

				&:hover,
				&:active {
					background-color: $c-teal-dark;
					border-color: $c-teal-dark;
				}
			}
		}
	}
}
