@import '../../sass/global.scss';

a {
	transition: all 200ms;
	color: $c-teal;
	text-decoration: none;

	&:hover {
		color: $c-teal-dark;
	}
}

.btn {
	display: inline-block;
  background-color: $c-orange;
	transition: all 200ms;
  border: 1px transparent solid;
  border-radius: 40px;
  padding: $gutter-s;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.4rem;
	text-align: center;
  width: 100%;
  color: $c-white;
  cursor: pointer;

  @media #{$mq-m} {
    width: auto;
  }

	svg {
		display: inline-block;
		vertical-align: middle;
		margin: 0 10px 0 0;
		height: 15px;
	}

	path {
		transition: all 200ms;
		fill: $c-white;
	}

	&:hover {
		background-color: $c-orange-dark;
		color: $c-white;
	}

  &:disabled {
    background-color: $c-pebble;
    cursor: default;
  }

	&:focus {
		outline: none;
	}

	&.btn--small {
		padding: 15px $gutter-s;
	}

	&.btn--full {
		width: 100%;
	}

	&.btn--teal {
		background-color: $c-teal;

		&:hover {
			background-color: $c-teal-dark;
		}
	}

	&.btn--gravel {
		background-color: $c-gravel;
		color: $c-white;

		&:hover {
			background-color: $c-gravel;
			color: $c-white;
		}
	}

	&.btn--rock {
		background-color: $c-rock;
		color: $c-white;

		&:hover {
			background-color: $c-stone;
			color: $c-white;
		}
	}

	&.btn--transparent {
		background-color: transparent;
		padding: 10px $gutter-s;
		border: none;
		width: auto;

		svg {
			margin: 0;
		}

		path {
			fill: $c-stone;
		}

		&:focus {

			path {
				fill: $c-orange;
			}
		}
	}

	&.btn--emoji {
		background-color: $c-alabaster;
		padding: 5px;
		text-align: center;
		font-size: 1.8rem;
		line-height: 1.8rem;

		&:hover {
			background-color: $c-smoke;
		}
	}

	&.btn--icon {
		border-radius: 4px;
		padding: 0;
		width: 40px;
		height: 40px;

		svg {
			margin: 0;
			height: 15px;
		}

		.btn__text {
			@include hidden();
		}
	}

	&.btn--pill {
		background-color: transparent;
		border: 2px $c-teal solid;
		border-radius: 60px;
		padding: 10px 15px;
		font-weight: 500;
		font-size: 1.2rem;
		line-height: 1.2rem;
		color: $c-teal;

		@media #{$mq-m} {
			font-size: 1.4rem;
			line-height: 1.4rem;
		}

		&.btn--active,
		&:hover {
			background-color: $c-teal;
			color: $c-white;
		}
	}

	&.btn--border {
		background-color: transparent;
		border-color: $c-orange;
		font-weight: 500;
		color: $c-orange;

		path {
			fill: $c-orange;
		}

		&:hover {
			background-color: $c-orange;
			color: $c-white;

			path {
				fill: $c-white;
			}
		}

		&.btn--boulder {
			border-color: $c-boulder;
			color: $c-boulder;

			path {
				fill: $c-boulder;
			}

			&:hover {
				background-color: $c-boulder;
				color: $c-white;

				path {
					fill: $c-white;
				}
			}
		}

		&.btn--rock {
			border-color: $c-rock;
			color: $c-gravel;

			path {
				fill: $c-gravel;
			}

			&:hover {
				background-color: $c-rock;
				color: $c-white;

				path {
					fill: $c-white;
				}
			}
		}
	}
}
