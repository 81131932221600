@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

html {
  font-family: 'Roboto';
  font-size: 62.5%;
  color: $c-boulder;
  -webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-size: 1.4rem;
	line-height: 2.4rem;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
  margin: 0 0 $gutter-s 0;
  font-weight: 500;
  color: $c-boulder;
}

h1,.h1,
h2,.h2,
h3,.h3 {
  font-weight: 300;
  color: $c-teal;
}

h1,.h1 {
  font-size: 3rem;
  line-height: 4rem;

  @media #{$mq-m} {
    font-size: 5rem;
    line-height: 6rem;
  }
}

h2,.h2 {
  font-size: 2.5rem;
  line-height: 3.5rem;

  @media #{$mq-m} {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
}

h3,.h3 {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.6rem;

  @media #{$mq-m} {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

h4,.h4 {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: $c-boulder;
}

.intro {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: $c-rock;

	@media #{$mq-m} {
		font-size: 2rem;
	  line-height: 3rem;
	}
}

p {
	margin: 0 0 15px 0;

	&:last-child {
		margin: 0;
	}
}
