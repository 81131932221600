@import '../../sass/global.scss';

.ephemeral {
	background-color: $c-teal-dark;
	padding: $gutter-s 0;
	color: $c-white;

	@media #{$mq-m} {
		padding: $gutter-m 0;
	}

	.ephemeral__wrap {
		grid-gap: $gutter-s;

		@media #{$mq-m} {
			grid-gap: $gutter-m;
		}
	}

	.ephemeral__grid {
		grid-column: 1 / -1;

		@media #{$mq-m} {
			display: grid;
			grid-template-columns: 1fr 3fr;
		}
	}

	.ephemeral__icon {

		@media #{$mq-l} {
			margin-bottom: 0;
		}

		svg {
			width: 35px;
			height: auto;
		}
	}

	.ephemeral__title {
		color: $c-white;
	}
}
