@import '../../sass/global.scss';

.unfurl {
	border-left: 4px $c-teal solid;
	padding: 0 0 0 $gutter-s;
	margin: $gutter-s 0 0 0;

	@media #{$mq-l} {
		margin: 0;
	}

	.unfurl__grid {
		display: grid;
		grid-template-columns: 3fr 1fr;
		grid-gap: $gutter-s;
	}

	.unfurl__title {
		margin: 0;

		a {
			margin: 0;
			display: block;
			color: $c-rock;
			font-size: 1.2rem;
			line-height: 2.2rem;

			@media #{$mq-m} {
				font-size: 1.4rem;
				line-height: 2.4rem;
			}

			&:hover {
				color: $c-teal;
			}
		}
	}

	.unfurl__description {
		font-size: 1.2rem;
		line-height: 2.2rem;
		word-break: break-word;

		@media #{$mq-m} {
			font-size: 1.4rem;
			line-height: 2.4rem;
		}
	}

	.unfurl__image {
		display: flex;
		justify-content: right;

		.image__img {
			border: 1px $c-stone solid;
			display: block;
			border-radius: 6px;
			width: 80px;
			height: 80px;
			object-fit: cover;
			margin: 0;
		}
	}
}
