@import '../../sass/global.scss';

.sidebar {
	transition: all 200ms ease-in-out;
	transform: translateX(100%);
	background-color: $c-alabaster;
	position: fixed;
	top: $chat-header-height;
	width: 100%;
	height: calc(100% - #{$chat-header-height});
	z-index: 200;

	@media #{$mq-l} {
		box-shadow: 2px 0px 10px rgba($c-black, 0.1);
		background-color: $c-white;
		transform: translateX(0%);
		top: 0;
		width: $sidebar-width;
		height: 100vh;
	}

	.sidebar__mask {
		padding: $gutter-s;
		height: 100%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	.sidebar__avatar {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-color: $c-smoke;
		margin: 0 auto $gutter-s auto;
		box-shadow: 0px 0px 20px rgba($c-black, 0.1);
		border-radius: 40px;
		display: block;
		width: 80px;
		height: 80px;
	}

	.sidebar__title {
		margin: 0;
	}

	.sidebar__address {
		margin: 10px 0 0 0;
		font-style: normal;
		font-size: 1.2rem;
		line-height: 2.2rem;
	}

	.sidebar__url {
		display: inline-block;
		margin: 10px 0 0 0;
	}

	.sidebar__users {
		margin: $gutter-s 0 0 0;

		@media #{$mq-m} {
			margin: $gutter-m 0 0 0;
		}

		.users__mask {
			position: relative;
			max-height: 205px;
			overflow-y: auto;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch;
		}

		.users__list {
			margin: 0;
			padding: 0;

			.list__user {
				background-color: $c-white;
				border: 1px $c-stone solid;
				transition: all 200ms;
				display: grid;
				grid-template-columns: 1.5fr 1fr;
				border-radius: 60px;
				margin: 0 0 10px 0;
				list-style: none;
				padding: 15px $gutter-s;
				align-items: center;
				font-size: 1.2rem;
				line-height: 1.2rem;
				font-weight: 500;
				color: $c-boulder;

				.user__dot {
					display: inline-block;
					background-color: $c-flint;
					border-radius: 5px;
					margin: 0 10px 0 0;
					width: 10px;
					height: 10px;
				}

				.user__username {
					display: inline-block;
				}

				.user__typing {
					display: none;

					span {
						opacity: 0;
					}

					@for $i from 1 through 3 {
						span:nth-child(#{$i}) {
							animation: blink 1s calc(100ms * (#{$i} - 1)) infinite;
						}
					}
				}

				.user__distance {
					text-align: right;
					position: relative;
					color: $c-rock;

					svg {
						display: inline-block;
						vertical-align: middle;
						margin: 0 10px 0 0;
						height: 14px;
					}

					path {
						fill: $c-stone;
					}
				}

				&.list__user--active {

					.user__typing {
						display: inline-block;
					}
				}

				&:last-child {
					margin: 0;
				}
			}
		}
	}

	.sidebar__btns {
		margin: $gutter-s 0;
		padding: 0;
		width: 100%;

		@media (min-height: #{$mq-m-num}) {
			position: absolute;
			bottom: $gutter-s;
			left: $gutter-s;
			margin: 0;
			width: calc(100% - #{$gutter-s-2x});
		}

		li {
			list-style: none;
		}
	}

	.sidebar__map {
		display: none;

		@media #{$mq-l} {
			display: inline-block;
		}
	}

	.sidebar__exit {

		svg,
		span {
			vertical-align: middle;
		}

		svg {
			display: inline-block;
			margin: 0 5px 0 0;
			height: 14px;
			width: auto;
		}
	}

	&.sidebar--active {
		transform: translateX(0%);
	}
}
