* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  height: 100%;
}

body {
	margin: 0;
}

ol,
ul {
	margin: 0 0 0 $gutter-s;
	padding: 0;

	li {
		margin: 0 0 $gutter-s 0;

		&:last-child {
			margin: 0;
		}
	}
}

.wrap {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
	grid-row-gap: $gutter-s;
  margin: 0 auto;
  max-width: 1366px;
  padding: 0 $gutter-s;

  @media #{$mq-m} {
    padding: 0 $gutter-m;
  }

	@media #{$mq-l} {
    grid-gap: $gutter-m;
  }

  @media #{$mq-max} {
    padding: 0;
  }
}

.section {
  padding: $gutter-s 0;

  @media #{$mq-m} {
    padding: $gutter-m 0;
  }

  @media #{$mq-l} {
    padding: $gutter-l 0;
  }

	&.section--short {
		@media #{$mq-l} {
	    padding: $gutter-m 0;
	  }
	}

  &.section--teal {
    color: $c-white;

    h1,.h1,
    h2,.h2,
    h3,.h3,
    h4,.h4,
    h5,.h4,
    h6,.h6 {
      color: $c-white;
    }
  }

  &.section--teal {
    background-color: $c-teal;
  }
}

.rooms {
  border-top: 1px $c-stone solid;

	.rooms__wrap {
		grid-gap: $gutter-s;

		@media #{$mq-m} {
			grid-gap: $gutter-m;
		}
	}

  .rooms__header {
    grid-column: 1 / -1;

		&.rooms__header--search {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: $gutter-s;
			align-items: end;

			@media #{$mq-m} {
				grid-gap: $gutter-m;
			}

			@media #{$mq-l} {
				grid-template-columns: 2fr 1fr;
			}
		}
  }

  .rooms__intro {

    @media #{$mq-m} {
      max-width: 650px;
    }
  }

	.rooms__types {
		display: flex;
		margin: 0;
		padding: 0;
		width: 100%;
		flex-wrap: wrap;

		@media #{$mq-m} {
			margin: $gutter-m 0 0 0;
		}

		li {
			list-style: none;
			margin: 0 $gutter-s $gutter-s 0;
		}
	}

  .rooms__list {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $gutter-s;
    margin: 0;
    padding: 0;

    @media #{$mq-m} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $gutter-m;
    }

    @media #{$mq-l} {
      grid-template-columns: repeat(4, 1fr);
    }

		li {
			margin: 0;
		}
  }

	.rooms__footer {
		grid-column: 1 / -1;
		display: flex;
		justify-content: flex-end;
	}

	&.rooms--events {

		.rooms__footer {

			.footer__credit {
				display: flex;
				align-items: center;
				font-style: italic;
				color: $c-rock;
			}

			svg {
				margin: 0 0 0 5px;
				height: 12px;
			}
		}
	}
}

.divider {

  &:after {
    background-color: $c-white;
    display: block;
    margin: $gutter-s 0;
    width: 40px;
    height: 2px;
    content: '';
  }
}

.tooltip {
  position: relative;
  cursor: pointer;

  &:after {
    content: attr(data-tooltip);
    transition: all 200ms;
    transform: translate(-50%, calc(-100% - 10px));
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba($c-black, 0.2);
    background-color: $c-white;
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    color: $c-black;
    pointer-events: none;
    opacity: 0;
  }

  &:hover {

    &:after {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.i {
	display: grid;
	align-content: center;
	justify-content: center;
	background-color: $c-teal;
	border-radius: 50px;
	margin: 0 auto $gutter-s auto;
	width: 100px;
	height: 100px;

	svg {
		display: inline-block;
		height: 44px;
	}

	path {
		fill: $c-white;
	}

	&.i--teal-dark {
		background-color: $c-teal-dark;
	}
}
