@import '../../sass/global.scss';

.map {
	grid-column: 1 / -1;

	.map__header {
		display: flex;
		align-items: center;
		font-weight: 700;
		color: $c-rock;

		span {
			background-color: $c-teal;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
			margin: 0 10px 0 0;
			width: 20px;
			height: 20px;
			color: $c-white;
		}
	}

	.map__map {
		background-color: $c-pebble;
		border-radius: 20px;
		margin: $gutter-s 0 0 0;
		width: 100%;
		height: calc(100vh - (#{$gutter-s} * 2));

		@media #{$mq-m} {
			height: 400px;
		}
	}

	.map__infowindow {

		.infowindow__title {
			width: calc(100% - #{$infowindow-close-width});
			margin: 0 0 10px 0;
		}

		.infowindow__users {
			margin: 0 0 $gutter-s-half 0;
			font-weight: 700;
			color: $c-rock;
		}
	}
}

// info window
.gm-style-iw-c {
	width: 260px;
	max-width: 260px !important;

	@media #{$mq-m} {
		width: 300px;
	}
}

// info window inside
.gm-style-iw-d {
	width: 100% !important;
	max-width: 100% !important;
}

// info window close btn
.gm-ui-hover-effect {
	transition: all 200ms;
	background-color: $c-pebble !important;
	border-radius: calc(#{$infowindow-close-width} / 2);
	display: flex !important;
	align-items: center;
	justify-content: center;
	top: 10px !important;
	right: 10px !important;
	padding: 0 !important;
	width: $infowindow-close-width !important;
	height: $infowindow-close-width !important;

	img {
		margin: 0 !important;
	}
}
