@import '../../sass/global.scss';

.mobile {
  position: relative;
  overflow: hidden;

  .mobile__map {

    svg {
      position: absolute;
      top: -100px;
      right: -100px;
      height: 100%;

      @media #{$mq-l} {
        height: auto;
      }
    }

    path {
      opacity: 0.1;
    }

    circle {
      transition: all 1s;
      opacity: 0;
    }
  }

  .mobile__wrap {
    position: relative;
    grid-row-gap: $gutter-s;

    @media #{$mq-m} {
      align-items: center;
      grid-gap: $gutter-m;
    }
  }

  .mobile__device {
    background-color: $c-white;
    border-radius: 40px;
    padding: 60px 10px;
    grid-column: 2 / -2;

    @media #{$mq-m} {
      grid-column: 1 / 5;
    }

    @media #{$mq-l} {
      grid-column: 2 / 5;
    }

    img {
      border: 1px $c-smoke solid;
      width: 100%;
      height: auto;
    }
  }

  .mobile__copy {
    grid-column: 1 / -1;
    text-align: left;

    @media #{$mq-m} {
      grid-column: 5 / -1;
    }

    @media #{$mq-l} {
      grid-column: 5 / -2;
    }

    @media #{$mq-xl} {
      grid-column: 6 / -2;
    }
  }

  &.mobile--active {

    .mobile__map {

      circle {

        @for $i from 1 through 30 {

          &:nth-child(#{$i}) {
            transition-delay: calc(0.5s * #{$i});
          }
        }

        @media #{$mq-m} {
          opacity: 1;
        }
      }
    }
  }
}
