@import '../../sass/global.scss';

.login {
  background-color: map-deep-get($material, 'cyan', '400');
  position: relative;
  width: 100%;
	height: 100%;
  min-height: 100vh;
  color: $c-white;

	@media #{$mq-l} {
		min-height: 500px;
		height: 100vh;
	}

  .login__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .login__wrap {
    position: relative;
    height: 100%;
    align-items: center;
    pointer-events: none;
  }

  .login__box {
    box-shadow: 0px 0px 60px rgba($c-black, 0.25);
    pointer-events: all;
    background-color: $c-white;
    border-radius: 20px;
    grid-column: 1 / -1;
    color: $c-boulder;
		overflow: hidden;

    @media #{$mq-m} {
      grid-column: 3 / -3;
    }

    @media #{$mq-l} {
      grid-column: 4 / -4;
    }

    ul {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: $gutter-s;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0;
      }
    }
  }

	.login__header {
		background-color: $c-teal;
		padding: $gutter-s;
		color: $c-white;

		@media #{$mq-m} {
			padding: $gutter-s calc(#{$gutter-s} + #{$gutter-s-half});
		}
	}

  .login__title {
		display: flex;
		align-items: center;
    margin: 0;
		color: $c-white;

    svg {
			margin: 0 10px 0 0;
      height: 30px;

			@media #{$mq-m} {
				height: 40px;
			}
    }
  }

	.login__form {
		padding: $gutter-s;

		@media #{$mq-m} {
			padding: $gutter-s-2x;
		}
	}
}

// place autocomplete

.pac-container {
	box-shadow: 0px 0px 40px rgba($c-black, 0.1);
	border: none;
  border-radius: 20px;
}

.pac-item {
  padding: 10px;
}

.pac-logo {
	padding: 0 0 $gutter-s 0;

	&:after {
		background-position: calc(100% - 20px) center;
	}
}
