label {
  display: block;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  margin: 0 0 $gutter-s 0;
}

input:not([type="checkbox"]),
select {
  -webkit-appearance: none;
}

input, select {
  background-color: $c-white;
  border: 1px #ccc solid;
  border-radius: 4px;
  padding: 15px;
  font-size: 1.4rem;
  line-height: 1.4rem;
  width: 100%;
}
