@import '../../sass/global.scss';

.emojipicker {
	display: none;

	@media #{$mq-l} {
		display: block;
	  transition: all 200ms;
	  transform: translateY(100%);
	  background-color: $c-white;
	  border-top: 1px $c-stone solid;
	  border-left: 1px $c-stone solid;
	  position: fixed;
	  bottom: $chat-form-height;
    right: 0;
    width: 500px;
    height: 300px;

		@media #{$mq-l} {
			bottom: $chat-form-height-l;
		}
  }

  .emojipicker__tabs {
    background-color: $c-alabaster;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    margin: 0;
    padding: 0;
    height: 60px;

    .tabs__item {
      list-style: none;
			margin: 0;
			height: 100%;

      .item__btn {
        border-radius: 0;
        padding: $gutter-s 0;
        width: 100%;
        text-align: center;
				height: 100%;

        path {
          fill: $c-rock;
        }

				&.btn--active,
        &:hover {
          background-color: $c-teal;

          path {
            fill: $c-white;
          }
        }

        &:focus {
          border-bottom: 2px $c-orange solid;
        }
      }
    }
  }

  .emojipicker__content {
    padding: $gutter-s;
    width: 100%;
    height: calc(100% - 60px);
  }

  .emojipicker__viewport {
    overflow-y: auto;
    overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
  }

  .emojipicker__title {
    font-weight: 700;
  }

  .emojipicker__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $gutter-s;
    margin: 0;
    padding: 0;

    ul {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
      margin: 0;
      padding: 0;

      @media #{$mq-m} {
        grid-template-columns: repeat(10, 1fr);
      }
    }

    li {
      list-style: none;
      margin: 0;

      button {
        width: 100%;
      }
    }
  }

  &.emojipicker--active {
    transform: translateY(0);
  }
}
