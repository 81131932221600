@import '../../sass/global.scss';

.card {
	background-color: $c-white;
	list-style: none;

	.card__btn {
		transition: all 200ms;
		background-color: transparent;
		box-shadow: 0px 0px 40px rgba($c-black, 0.1);
		border: none;
		border-radius: 20px;
		padding: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		cursor: pointer;

		&:hover {

			@media #{$mq-l} {
				transform: scale(1.1);
			}
		}
	}

	.card__image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 160px;

		i {
			background-color: $c-teal-dark;
			border-radius: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 60px;
			height: 60px;
		}

		svg {
			display: inline-block;
			height: 36px;
			max-width: 36px;
		}

		img {
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
		}
	}

	.card__strapline {
		margin: 0 0 5px 0;
		font-weight: 600;
		font-size: 1.2rem;
		line-height: 1.2rem;
		color: $c-gravel;
	}

	.card__header {
		display: flex;
		justify-content: space-between;
	}

	.card__icons {
		margin: 0;
		padding: 0;

		li {
			display: flex;
			list-style: none;
			font-size: 1.4rem;
			line-height: 1.4rem;
			color: $c-rock;
		}

		svg {
			margin: 0 10px 0 0;
			vertical-align: middle;
			height: 18px;
		}

		path {
			fill: $c-teal;
		}
	}

	.card__content {
		background-color: $c-white;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: left;
		padding: $gutter-s;
		width: 100%;
		height: calc(100% - 160px);
	}

	.card__title {
		font-weight: 500;
		margin: 0;
		color: $c-boulder;
	}

	.card__footer {
		display: flex;
		justify-content: space-between;
		margin: $gutter-s 0 0 0;
		font-weight: 600;
		color: $c-gravel;
	}

	.card__location,
	.card__distance {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	.card__icon {

		svg {
			margin: 0;
			height: 14px;
		}

		path {
			fill: $c-teal;
		}
	}

	&.card--noimage {

		.card__image {
			background-color: $c-teal;
		}
	}
}
