@import '../../sass/global.scss';

.chat__map {
	transition: all 200ms;
	transform: translateX(100%);
	position: fixed;
	top: $chat-header-height;
	right: 0;
	width: 100%;
	height: calc(100vh - #{$chat-header-height});
	z-index: 100;

	@media #{$mq-l} {
		top: 0;
		height: 100vh;
		width: calc(100% - #{$sidebar-width});
	}

	.map__map {
		border-radius: 0px;
		margin: 0;
		width: 100%;
		height: 100%;
	}

	&.chat__map--active {
		transform: translateX(0);
	}
}
