// mq

$mq-m-num: 768px;
$mq-l-num: 1024px;
$mq-xl-num: 1200px;
$mq-max-num: 1366px;

$mq-m: 'screen and (min-width: #{$mq-m-num})';
$mq-l: 'screen and (min-width: #{$mq-l-num})';
$mq-xl: 'screen and (min-width: #{$mq-xl-num})';
$mq-max: 'screen and (min-width: #{$mq-max-num})';

// gutters

$gutter-s: 20px;
$gutter-m: 50px;
$gutter-l: 120px;

$gutter-s-half: calc(#{$gutter-s} / 2);
$gutter-m-half: calc(#{$gutter-m} / 2);
$gutter-l-half: calc(#{$gutter-l} / 2);

$gutter-s-2x: calc(#{$gutter-s} * 2);
$gutter-m-2x: calc(#{$gutter-m} * 2);
$gutter-l-2x: calc(#{$gutter-l} * 2);

// base

$c-white: #ffffff;
$c-black: #000000;

// light greys

$c-alabaster: #fafafa;
$c-pebble: #eeeeee;
$c-smoke: #e6e6e6;
$c-flint: #e1e1e1;
$c-stone: #cccccc;
$c-rock: #b2b2b2;
$c-gravel: #9c9c9c;
$c-boulder: #525252;

// dark greys

$c-mine: #282828;
$c-slate: #333333;
$c-coal: #1f1f1f;
$c-obsidian: #111111;

// brands

$c-facebook: #3b5998;
$c-twitter: #1da1f2;
$c-linkedin: #0077b5;

// app

$c-teal: map-deep-get($material, 'teal', 'a700');
$c-teal-dark: map-deep-get($material, 'teal', '600');

$c-orange: map-deep-get($material, 'orange', '600');
$c-orange-dark: map-deep-get($material, 'orange', '800');

$c-red: map-deep-get($material, 'red', '600');
$c-red-dark: map-deep-get($material, 'red', '800');

// misc

$chat-header-height: 60px;
$chat-form-height: 60px;
$chat-form-height-l: 90px;
$ios-navigation-bar-height: 114px;

$sidebar-width: 300px;

$infowindow-close-width: 30px;
