@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.card {
  background-color: #ffffff;
  list-style: none;
}
.card .card__btn {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  background-color: transparent;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 20px;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .card .card__btn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
.card .card__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
}
.card .card__image i {
  background-color: #00897b;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}
.card .card__image svg {
  display: inline-block;
  height: 36px;
  max-width: 36px;
}
.card .card__image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.card .card__strapline {
  margin: 0 0 5px 0;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #9c9c9c;
}
.card .card__header {
  display: flex;
  justify-content: space-between;
}
.card .card__icons {
  margin: 0;
  padding: 0;
}
.card .card__icons li {
  display: flex;
  list-style: none;
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: #b2b2b2;
}
.card .card__icons svg {
  margin: 0 10px 0 0;
  vertical-align: middle;
  height: 18px;
}
.card .card__icons path {
  fill: #00bfa5;
}
.card .card__content {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding: 20px;
  width: 100%;
  height: calc(100% - 160px);
}
.card .card__title {
  font-weight: 500;
  margin: 0;
  color: #525252;
}
.card .card__footer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
  font-weight: 600;
  color: #9c9c9c;
}
.card .card__location,
.card .card__distance {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.card .card__icon svg {
  margin: 0;
  height: 14px;
}
.card .card__icon path {
  fill: #00bfa5;
}
.card.card--noimage .card__image {
  background-color: #00bfa5;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.map {
  grid-column: 1/-1;
}
.map .map__header {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #b2b2b2;
}
.map .map__header span {
  background-color: #00bfa5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  color: #ffffff;
}
.map .map__map {
  background-color: #eeeeee;
  border-radius: 20px;
  margin: 20px 0 0 0;
  width: 100%;
  height: calc(100vh - 20px * 2);
}
@media screen and (min-width: 768px) {
  .map .map__map {
    height: 400px;
  }
}
.map .map__infowindow .infowindow__title {
  width: calc(100% - 30px);
  margin: 0 0 10px 0;
}
.map .map__infowindow .infowindow__users {
  margin: 0 0 calc(20px / 2) 0;
  font-weight: 700;
  color: #b2b2b2;
}

.gm-style-iw-c {
  width: 260px;
  max-width: 260px !important;
}
@media screen and (min-width: 768px) {
  .gm-style-iw-c {
    width: 300px;
  }
}

.gm-style-iw-d {
  width: 100% !important;
  max-width: 100% !important;
}

.gm-ui-hover-effect {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  background-color: #eeeeee !important;
  border-radius: calc(30px / 2);
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: 10px !important;
  right: 10px !important;
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
}
.gm-ui-hover-effect img {
  margin: 0 !important;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ephemeral {
  background-color: #00897b;
  padding: 20px 0;
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .ephemeral {
    padding: 50px 0;
  }
}
.ephemeral .ephemeral__wrap {
  grid-gap: 20px;
}
@media screen and (min-width: 768px) {
  .ephemeral .ephemeral__wrap {
    grid-gap: 50px;
  }
}
.ephemeral .ephemeral__grid {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .ephemeral .ephemeral__grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
}
@media screen and (min-width: 1024px) {
  .ephemeral .ephemeral__icon {
    margin-bottom: 0;
  }
}
.ephemeral .ephemeral__icon svg {
  width: 35px;
  height: auto;
}
.ephemeral .ephemeral__title {
  color: #ffffff;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.footer {
  border-top: 1px #e1e1e1 solid;
  padding: 20px 0;
}
.footer .footer__company {
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .footer .footer__company {
    display: flex;
    align-items: center;
    grid-column: 1/9;
  }
}
.footer .footer__company strong {
  color: #00bfa5;
}
@media screen and (min-width: 768px) {
  .footer .footer__copyright {
    display: flex;
  }
}
.footer .footer__logo {
  margin: 0 0 calc(20px / 2) 0;
  display: flex;
  align-items: center;
  width: auto;
}
@media screen and (min-width: 768px) {
  .footer .footer__logo {
    margin: 0 5px 0 0;
  }
}
.footer .footer__logo img {
  margin: 0 5px 0 0;
  display: block;
  width: 20px;
  height: 20px;
}
.footer .footer__nav {
  display: flex;
  margin: calc(20px / 2) 0 0 0;
  padding: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .footer .footer__nav {
    margin: 0 0 0 20px;
    width: auto;
  }
}
.footer .footer__nav li {
  margin: 0 10px 0 0;
  list-style: none;
}
.footer .footer__nav li:last-child {
  margin: 0;
}
.footer .footer__credit {
  display: flex;
  align-items: center;
  grid-column: 1/-1;
}
@media screen and (min-width: 768px) {
  .footer .footer__credit {
    grid-column: 9/-1;
    justify-content: flex-end;
  }
}
.footer .footer__credit svg {
  vertical-align: middle;
  margin: 0 5px;
  height: 15px;
}
.footer .footer__credit path {
  fill: #e53935;
}
.footer .footer__credit a {
  margin: 0 0 0 5px;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.homescreen {
  background-color: #00897b;
  padding: 20px 0;
  color: #ffffff;
}
@media screen and (min-width: 1024px) {
  .homescreen {
    display: none;
  }
}
.homescreen .homescreen__wrap {
  grid-gap: 20px;
}
.homescreen .homescreen__image {
  grid-column: 1/4;
}
@media screen and (min-width: 768px) {
  .homescreen .homescreen__image {
    grid-column: 1/2;
  }
}
.homescreen .homescreen__image img {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  display: block;
  border-radius: 12px;
  width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) {
  .homescreen .homescreen__image img {
    border-radius: 8px;
  }
}
.homescreen .homescreen__copy {
  grid-column: 4/-1;
}
@media screen and (min-width: 768px) {
  .homescreen .homescreen__copy {
    grid-column: 2/-1;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;
  }
}
.homescreen .homescreen__title {
  margin: 0;
  color: #ffffff;
}
.homescreen .homescreen__bar {
  display: none;
}
@supports (-webkit-overflow-scrolling: touch) {
  .homescreen .homescreen__bar {
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-columns: 3fr 1fr;
    border-radius: 8px;
    grid-column: 1/-1;
    padding: 10px 15px;
    color: #000000;
  }
}
@media screen and (min-width: 768px) {
  .homescreen .homescreen__bar {
    display: none;
  }
}
.homescreen .homescreen__bar .bar__icon {
  text-align: right;
}
.homescreen .homescreen__bar .bar__icon span {
  border: 1px #000000 solid;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  padding: 2px 0 0 0;
  width: 20px;
  height: 20px;
}
.homescreen .homescreen__android {
  grid-column: 1/-1;
}
@supports (-webkit-overflow-scrolling: touch) {
  .homescreen .homescreen__android {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .homescreen .homescreen__android {
    display: none;
  }
}
.homescreen .homescreen__android img {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: block;
  width: 100%;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
a {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  color: #00bfa5;
  text-decoration: none;
}
a:hover {
  color: #00897b;
}

.btn {
  display: inline-block;
  background-color: #fb8c00;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  border: 1px transparent solid;
  border-radius: 40px;
  padding: 20px;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-align: center;
  width: 100%;
  color: #ffffff;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .btn {
    width: auto;
  }
}
.btn svg {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  height: 15px;
}
.btn path {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  fill: #ffffff;
}
.btn:hover {
  background-color: #ef6c00;
  color: #ffffff;
}
.btn:disabled {
  background-color: #eeeeee;
  cursor: default;
}
.btn:focus {
  outline: none;
}
.btn.btn--small {
  padding: 15px 20px;
}
.btn.btn--full {
  width: 100%;
}
.btn.btn--teal {
  background-color: #00bfa5;
}
.btn.btn--teal:hover {
  background-color: #00897b;
}
.btn.btn--gravel {
  background-color: #9c9c9c;
  color: #ffffff;
}
.btn.btn--gravel:hover {
  background-color: #9c9c9c;
  color: #ffffff;
}
.btn.btn--rock {
  background-color: #b2b2b2;
  color: #ffffff;
}
.btn.btn--rock:hover {
  background-color: #cccccc;
  color: #ffffff;
}
.btn.btn--transparent {
  background-color: transparent;
  padding: 10px 20px;
  border: none;
  width: auto;
}
.btn.btn--transparent svg {
  margin: 0;
}
.btn.btn--transparent path {
  fill: #cccccc;
}
.btn.btn--transparent:focus path {
  fill: #fb8c00;
}
.btn.btn--emoji {
  background-color: #fafafa;
  padding: 5px;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.8rem;
}
.btn.btn--emoji:hover {
  background-color: #e6e6e6;
}
.btn.btn--icon {
  border-radius: 4px;
  padding: 0;
  width: 40px;
  height: 40px;
}
.btn.btn--icon svg {
  margin: 0;
  height: 15px;
}
.btn.btn--icon .btn__text {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}
.btn.btn--pill {
  background-color: transparent;
  border: 2px #00bfa5 solid;
  border-radius: 60px;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #00bfa5;
}
@media screen and (min-width: 768px) {
  .btn.btn--pill {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}
.btn.btn--pill.btn--active, .btn.btn--pill:hover {
  background-color: #00bfa5;
  color: #ffffff;
}
.btn.btn--border {
  background-color: transparent;
  border-color: #fb8c00;
  font-weight: 500;
  color: #fb8c00;
}
.btn.btn--border path {
  fill: #fb8c00;
}
.btn.btn--border:hover {
  background-color: #fb8c00;
  color: #ffffff;
}
.btn.btn--border:hover path {
  fill: #ffffff;
}
.btn.btn--border.btn--boulder {
  border-color: #525252;
  color: #525252;
}
.btn.btn--border.btn--boulder path {
  fill: #525252;
}
.btn.btn--border.btn--boulder:hover {
  background-color: #525252;
  color: #ffffff;
}
.btn.btn--border.btn--boulder:hover path {
  fill: #ffffff;
}
.btn.btn--border.btn--rock {
  border-color: #b2b2b2;
  color: #9c9c9c;
}
.btn.btn--border.btn--rock path {
  fill: #9c9c9c;
}
.btn.btn--border.btn--rock:hover {
  background-color: #b2b2b2;
  color: #ffffff;
}
.btn.btn--border.btn--rock:hover path {
  fill: #ffffff;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.login {
  background-color: #26c6da;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  color: #ffffff;
}
@media screen and (min-width: 1024px) {
  .login {
    min-height: 500px;
    height: 100vh;
  }
}
.login .login__map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.login .login__wrap {
  position: relative;
  height: 100%;
  align-items: center;
  pointer-events: none;
}
.login .login__box {
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
  pointer-events: all;
  background-color: #ffffff;
  border-radius: 20px;
  grid-column: 1/-1;
  color: #525252;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .login .login__box {
    grid-column: 3/-3;
  }
}
@media screen and (min-width: 1024px) {
  .login .login__box {
    grid-column: 4/-4;
  }
}
.login .login__box ul {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 0;
  padding: 0;
}
.login .login__box ul li {
  list-style: none;
  margin: 0;
}
.login .login__header {
  background-color: #00bfa5;
  padding: 20px;
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .login .login__header {
    padding: 20px calc(20px + calc(20px / 2));
  }
}
.login .login__title {
  display: flex;
  align-items: center;
  margin: 0;
  color: #ffffff;
}
.login .login__title svg {
  margin: 0 10px 0 0;
  height: 30px;
}
@media screen and (min-width: 768px) {
  .login .login__title svg {
    height: 40px;
  }
}
.login .login__form {
  padding: 20px;
}
@media screen and (min-width: 768px) {
  .login .login__form {
    padding: calc(20px * 2);
  }
}

.pac-container {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 20px;
}

.pac-item {
  padding: 10px;
}

.pac-logo {
  padding: 0 0 20px 0;
}
.pac-logo:after {
  background-position: calc(100% - 20px) center;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mobile {
  position: relative;
  overflow: hidden;
}
.mobile .mobile__map svg {
  position: absolute;
  top: -100px;
  right: -100px;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  .mobile .mobile__map svg {
    height: auto;
  }
}
.mobile .mobile__map path {
  opacity: 0.1;
}
.mobile .mobile__map circle {
  -webkit-transition: all 1s;
  transition: all 1s;
  opacity: 0;
}
.mobile .mobile__wrap {
  position: relative;
  grid-row-gap: 20px;
}
@media screen and (min-width: 768px) {
  .mobile .mobile__wrap {
    align-items: center;
    grid-gap: 50px;
  }
}
.mobile .mobile__device {
  background-color: #ffffff;
  border-radius: 40px;
  padding: 60px 10px;
  grid-column: 2/-2;
}
@media screen and (min-width: 768px) {
  .mobile .mobile__device {
    grid-column: 1/5;
  }
}
@media screen and (min-width: 1024px) {
  .mobile .mobile__device {
    grid-column: 2/5;
  }
}
.mobile .mobile__device img {
  border: 1px #e6e6e6 solid;
  width: 100%;
  height: auto;
}
.mobile .mobile__copy {
  grid-column: 1/-1;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .mobile .mobile__copy {
    grid-column: 5/-1;
  }
}
@media screen and (min-width: 1024px) {
  .mobile .mobile__copy {
    grid-column: 5/-2;
  }
}
@media screen and (min-width: 1200px) {
  .mobile .mobile__copy {
    grid-column: 6/-2;
  }
}
.mobile.mobile--active .mobile__map circle:nth-child(1) {
  -webkit-transition-delay: calc(0.5s * 1);
          transition-delay: calc(0.5s * 1);
}
.mobile.mobile--active .mobile__map circle:nth-child(2) {
  -webkit-transition-delay: calc(0.5s * 2);
          transition-delay: calc(0.5s * 2);
}
.mobile.mobile--active .mobile__map circle:nth-child(3) {
  -webkit-transition-delay: calc(0.5s * 3);
          transition-delay: calc(0.5s * 3);
}
.mobile.mobile--active .mobile__map circle:nth-child(4) {
  -webkit-transition-delay: calc(0.5s * 4);
          transition-delay: calc(0.5s * 4);
}
.mobile.mobile--active .mobile__map circle:nth-child(5) {
  -webkit-transition-delay: calc(0.5s * 5);
          transition-delay: calc(0.5s * 5);
}
.mobile.mobile--active .mobile__map circle:nth-child(6) {
  -webkit-transition-delay: calc(0.5s * 6);
          transition-delay: calc(0.5s * 6);
}
.mobile.mobile--active .mobile__map circle:nth-child(7) {
  -webkit-transition-delay: calc(0.5s * 7);
          transition-delay: calc(0.5s * 7);
}
.mobile.mobile--active .mobile__map circle:nth-child(8) {
  -webkit-transition-delay: calc(0.5s * 8);
          transition-delay: calc(0.5s * 8);
}
.mobile.mobile--active .mobile__map circle:nth-child(9) {
  -webkit-transition-delay: calc(0.5s * 9);
          transition-delay: calc(0.5s * 9);
}
.mobile.mobile--active .mobile__map circle:nth-child(10) {
  -webkit-transition-delay: calc(0.5s * 10);
          transition-delay: calc(0.5s * 10);
}
.mobile.mobile--active .mobile__map circle:nth-child(11) {
  -webkit-transition-delay: calc(0.5s * 11);
          transition-delay: calc(0.5s * 11);
}
.mobile.mobile--active .mobile__map circle:nth-child(12) {
  -webkit-transition-delay: calc(0.5s * 12);
          transition-delay: calc(0.5s * 12);
}
.mobile.mobile--active .mobile__map circle:nth-child(13) {
  -webkit-transition-delay: calc(0.5s * 13);
          transition-delay: calc(0.5s * 13);
}
.mobile.mobile--active .mobile__map circle:nth-child(14) {
  -webkit-transition-delay: calc(0.5s * 14);
          transition-delay: calc(0.5s * 14);
}
.mobile.mobile--active .mobile__map circle:nth-child(15) {
  -webkit-transition-delay: calc(0.5s * 15);
          transition-delay: calc(0.5s * 15);
}
.mobile.mobile--active .mobile__map circle:nth-child(16) {
  -webkit-transition-delay: calc(0.5s * 16);
          transition-delay: calc(0.5s * 16);
}
.mobile.mobile--active .mobile__map circle:nth-child(17) {
  -webkit-transition-delay: calc(0.5s * 17);
          transition-delay: calc(0.5s * 17);
}
.mobile.mobile--active .mobile__map circle:nth-child(18) {
  -webkit-transition-delay: calc(0.5s * 18);
          transition-delay: calc(0.5s * 18);
}
.mobile.mobile--active .mobile__map circle:nth-child(19) {
  -webkit-transition-delay: calc(0.5s * 19);
          transition-delay: calc(0.5s * 19);
}
.mobile.mobile--active .mobile__map circle:nth-child(20) {
  -webkit-transition-delay: calc(0.5s * 20);
          transition-delay: calc(0.5s * 20);
}
.mobile.mobile--active .mobile__map circle:nth-child(21) {
  -webkit-transition-delay: calc(0.5s * 21);
          transition-delay: calc(0.5s * 21);
}
.mobile.mobile--active .mobile__map circle:nth-child(22) {
  -webkit-transition-delay: calc(0.5s * 22);
          transition-delay: calc(0.5s * 22);
}
.mobile.mobile--active .mobile__map circle:nth-child(23) {
  -webkit-transition-delay: calc(0.5s * 23);
          transition-delay: calc(0.5s * 23);
}
.mobile.mobile--active .mobile__map circle:nth-child(24) {
  -webkit-transition-delay: calc(0.5s * 24);
          transition-delay: calc(0.5s * 24);
}
.mobile.mobile--active .mobile__map circle:nth-child(25) {
  -webkit-transition-delay: calc(0.5s * 25);
          transition-delay: calc(0.5s * 25);
}
.mobile.mobile--active .mobile__map circle:nth-child(26) {
  -webkit-transition-delay: calc(0.5s * 26);
          transition-delay: calc(0.5s * 26);
}
.mobile.mobile--active .mobile__map circle:nth-child(27) {
  -webkit-transition-delay: calc(0.5s * 27);
          transition-delay: calc(0.5s * 27);
}
.mobile.mobile--active .mobile__map circle:nth-child(28) {
  -webkit-transition-delay: calc(0.5s * 28);
          transition-delay: calc(0.5s * 28);
}
.mobile.mobile--active .mobile__map circle:nth-child(29) {
  -webkit-transition-delay: calc(0.5s * 29);
          transition-delay: calc(0.5s * 29);
}
.mobile.mobile--active .mobile__map circle:nth-child(30) {
  -webkit-transition-delay: calc(0.5s * 30);
          transition-delay: calc(0.5s * 30);
}
@media screen and (min-width: 768px) {
  .mobile.mobile--active .mobile__map circle {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.why .why__wrap {
  grid-gap: 20px;
}
@media screen and (min-width: 768px) {
  .why .why__wrap {
    grid-gap: 50px;
  }
}
.why .why__header {
  grid-column: 1/-1;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .why .why__header {
    grid-column: 2/-2;
  }
}
@media screen and (min-width: 1024px) {
  .why .why__header {
    grid-column: 3/-3;
  }
}
.why .why__intro {
  color: #ffffff;
}
.why .why__list {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .why .why__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .why .why__list {
    grid-gap: 50px;
  }
}
.why .why__list li {
  list-style: none;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .why .why__list li {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}
.home {
  height: 100%;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.chatheader {
  border-bottom: 1px #e6e6e6 solid;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  height: 60px;
  padding: 0 calc(20px / 2);
  z-index: 100;
}
@media screen and (min-width: 1024px) {
  .chatheader {
    display: none;
  }
}
.chatheader .chatheader__title {
  grid-column: 3/10;
  text-align: center;
  margin: 0;
}
.chatheader .chatheader__list {
  grid-column: 10/-1;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}
.chatheader .chatheader__list li {
  margin: 0 0 0 calc(20px / 2);
  list-style: none;
}
.chatheader .chatheader__list li:first-child {
  margin: 0;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sidebar {
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  background-color: #fafafa;
  position: fixed;
  top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  z-index: 200;
}
@media screen and (min-width: 1024px) {
  .sidebar {
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    top: 0;
    width: 300px;
    height: 100vh;
  }
}
.sidebar .sidebar__mask {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.sidebar .sidebar__avatar {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #e6e6e6;
  margin: 0 auto 20px auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  display: block;
  width: 80px;
  height: 80px;
}
.sidebar .sidebar__title {
  margin: 0;
}
.sidebar .sidebar__address {
  margin: 10px 0 0 0;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 2.2rem;
}
.sidebar .sidebar__url {
  display: inline-block;
  margin: 10px 0 0 0;
}
.sidebar .sidebar__users {
  margin: 20px 0 0 0;
}
@media screen and (min-width: 768px) {
  .sidebar .sidebar__users {
    margin: 50px 0 0 0;
  }
}
.sidebar .sidebar__users .users__mask {
  position: relative;
  max-height: 205px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.sidebar .sidebar__users .users__list {
  margin: 0;
  padding: 0;
}
.sidebar .sidebar__users .users__list .list__user {
  background-color: #ffffff;
  border: 1px #cccccc solid;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  border-radius: 60px;
  margin: 0 0 10px 0;
  list-style: none;
  padding: 15px 20px;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 500;
  color: #525252;
}
.sidebar .sidebar__users .users__list .list__user .user__dot {
  display: inline-block;
  background-color: #e1e1e1;
  border-radius: 5px;
  margin: 0 10px 0 0;
  width: 10px;
  height: 10px;
}
.sidebar .sidebar__users .users__list .list__user .user__username {
  display: inline-block;
}
.sidebar .sidebar__users .users__list .list__user .user__typing {
  display: none;
}
.sidebar .sidebar__users .users__list .list__user .user__typing span {
  opacity: 0;
}
.sidebar .sidebar__users .users__list .list__user .user__typing span:nth-child(1) {
  -webkit-animation: blink 1s calc(100ms * (1 - 1)) infinite;
          animation: blink 1s calc(100ms * (1 - 1)) infinite;
}
.sidebar .sidebar__users .users__list .list__user .user__typing span:nth-child(2) {
  -webkit-animation: blink 1s calc(100ms * (2 - 1)) infinite;
          animation: blink 1s calc(100ms * (2 - 1)) infinite;
}
.sidebar .sidebar__users .users__list .list__user .user__typing span:nth-child(3) {
  -webkit-animation: blink 1s calc(100ms * (3 - 1)) infinite;
          animation: blink 1s calc(100ms * (3 - 1)) infinite;
}
.sidebar .sidebar__users .users__list .list__user .user__distance {
  text-align: right;
  position: relative;
  color: #b2b2b2;
}
.sidebar .sidebar__users .users__list .list__user .user__distance svg {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  height: 14px;
}
.sidebar .sidebar__users .users__list .list__user .user__distance path {
  fill: #cccccc;
}
.sidebar .sidebar__users .users__list .list__user.list__user--active .user__typing {
  display: inline-block;
}
.sidebar .sidebar__users .users__list .list__user:last-child {
  margin: 0;
}
.sidebar .sidebar__btns {
  margin: 20px 0;
  padding: 0;
  width: 100%;
}
@media (min-height: 768px) {
  .sidebar .sidebar__btns {
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    width: calc(100% - calc(20px * 2));
  }
}
.sidebar .sidebar__btns li {
  list-style: none;
}
.sidebar .sidebar__map {
  display: none;
}
@media screen and (min-width: 1024px) {
  .sidebar .sidebar__map {
    display: inline-block;
  }
}
.sidebar .sidebar__exit svg,
.sidebar .sidebar__exit span {
  vertical-align: middle;
}
.sidebar .sidebar__exit svg {
  display: inline-block;
  margin: 0 5px 0 0;
  height: 14px;
  width: auto;
}
.sidebar.sidebar--active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.emojipicker {
  display: none;
}
@media screen and (min-width: 1024px) {
  .emojipicker {
    display: block;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    background-color: #ffffff;
    border-top: 1px #cccccc solid;
    border-left: 1px #cccccc solid;
    position: fixed;
    bottom: 60px;
    right: 0;
    width: 500px;
    height: 300px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  .emojipicker {
    bottom: 90px;
  }
}
.emojipicker .emojipicker__tabs {
  background-color: #fafafa;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  margin: 0;
  padding: 0;
  height: 60px;
}
.emojipicker .emojipicker__tabs .tabs__item {
  list-style: none;
  margin: 0;
  height: 100%;
}
.emojipicker .emojipicker__tabs .tabs__item .item__btn {
  border-radius: 0;
  padding: 20px 0;
  width: 100%;
  text-align: center;
  height: 100%;
}
.emojipicker .emojipicker__tabs .tabs__item .item__btn path {
  fill: #b2b2b2;
}
.emojipicker .emojipicker__tabs .tabs__item .item__btn.btn--active, .emojipicker .emojipicker__tabs .tabs__item .item__btn:hover {
  background-color: #00bfa5;
}
.emojipicker .emojipicker__tabs .tabs__item .item__btn.btn--active path, .emojipicker .emojipicker__tabs .tabs__item .item__btn:hover path {
  fill: #ffffff;
}
.emojipicker .emojipicker__tabs .tabs__item .item__btn:focus {
  border-bottom: 2px #fb8c00 solid;
}
.emojipicker .emojipicker__content {
  padding: 20px;
  width: 100%;
  height: calc(100% - 60px);
}
.emojipicker .emojipicker__viewport {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
}
.emojipicker .emojipicker__title {
  font-weight: 700;
}
.emojipicker .emojipicker__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 0;
  padding: 0;
}
.emojipicker .emojipicker__list ul {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .emojipicker .emojipicker__list ul {
    grid-template-columns: repeat(10, 1fr);
  }
}
.emojipicker .emojipicker__list li {
  list-style: none;
  margin: 0;
}
.emojipicker .emojipicker__list li button {
  width: 100%;
}
.emojipicker.emojipicker--active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.addmessage {
  background-color: #ffffff;
  border-top: 1px #cccccc solid;
  display: grid;
  grid-template-columns: 16fr 2fr;
  grid-gap: calc(20px / 2);
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: calc(20px / 2);
  width: 100%;
  height: 60px;
}
@media screen and (min-width: 1024px) {
  .addmessage {
    position: absolute;
    height: 90px;
    padding: 20px;
    grid-gap: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .addmessage {
    grid-template-columns: 16fr 3fr;
  }
}
.addmessage .addmessage__text {
  position: relative;
}
.addmessage .addmessage__text input {
  padding-right: 70px;
}
.addmessage .addmessage__addemoji {
  background-color: transparent;
  position: absolute;
  padding: 0;
  right: 40px;
  width: auto;
}
.addmessage .addmessage__addemoji svg,
.addmessage .addmessage__file svg {
  margin: 0;
  height: 20px;
}
.addmessage .addmessage__addemoji path,
.addmessage .addmessage__file path {
  fill: #cccccc;
}
.addmessage .addmessage__addemoji {
  display: none;
}
@media screen and (min-width: 1024px) {
  .addmessage .addmessage__addemoji {
    display: inline-block;
  }
}
.addmessage .addmessage__file label {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.addmessage .addmessage__file input {
  display: none;
}
.addmessage input,
.addmessage button {
  padding: calc(20px / 2);
  height: 40px;
}
@media screen and (min-width: 1024px) {
  .addmessage input,
  .addmessage button {
    padding: calc(20px / 2) 15px;
    height: 44px;
  }
}
.addmessage .addmessage__btn {
  border-radius: 4px;
}
@media screen and (min-width: 1024px) {
  .addmessage .addmessage__btn {
    border-radius: 40px;
  }
}
.addmessage .addmessage__btn svg {
  margin: 0;
}
@media screen and (min-width: 1200px) {
  .addmessage .addmessage__btn svg {
    margin: 0 10px 0 0;
  }
}
.addmessage .addmessage__btn .btn__text {
  display: none;
}
@media screen and (min-width: 1200px) {
  .addmessage .addmessage__btn .btn__text {
    display: inline-block;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.unfurl {
  border-left: 4px #00bfa5 solid;
  padding: 0 0 0 20px;
  margin: 20px 0 0 0;
}
@media screen and (min-width: 1024px) {
  .unfurl {
    margin: 0;
  }
}
.unfurl .unfurl__grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 20px;
}
.unfurl .unfurl__title {
  margin: 0;
}
.unfurl .unfurl__title a {
  margin: 0;
  display: block;
  color: #b2b2b2;
  font-size: 1.2rem;
  line-height: 2.2rem;
}
@media screen and (min-width: 768px) {
  .unfurl .unfurl__title a {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}
.unfurl .unfurl__title a:hover {
  color: #00bfa5;
}
.unfurl .unfurl__description {
  font-size: 1.2rem;
  line-height: 2.2rem;
  word-break: break-word;
}
@media screen and (min-width: 768px) {
  .unfurl .unfurl__description {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}
.unfurl .unfurl__image {
  display: flex;
  justify-content: right;
}
.unfurl .unfurl__image .image__img {
  border: 1px #cccccc solid;
  display: block;
  border-radius: 6px;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 0;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.viewport {
  position: fixed;
  top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .viewport {
    top: 0;
    left: 300px;
    width: calc(100vw - 300px);
    height: calc(100% - 60px);
    margin: 0;
    height: 100%;
  }
}
.viewport .viewport__chat {
  height: 100%;
}
.viewport .viewport__list {
  padding: 0;
  float: left;
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0;
}
@media screen and (min-width: 1024px) {
  .viewport .viewport__list {
    margin: 0;
    height: calc(100vh - 90px);
  }
}
.viewport .viewport__list li {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr;
  align-items: center;
  list-style: none;
  padding: 20px;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  word-break: break-all;
  width: 100%;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .viewport .viewport__list li {
    grid-gap: calc(20px / 2);
  }
}
@media screen and (min-width: 1024px) {
  .viewport .viewport__list li {
    grid-template-columns: 11fr 1fr;
  }
}
.viewport .viewport__list li .message__time {
  color: #b2b2b2;
  font-size: 1rem;
  line-height: 1rem;
}
@media screen and (min-width: 768px) {
  .viewport .viewport__list li .message__time {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .viewport .viewport__list li .message__time {
    text-align: right;
  }
}
.viewport .viewport__list li img {
  display: block;
  margin: 20px 0 0 0;
  max-width: 100%;
}
.viewport .viewport__list li:nth-child(odd) {
  background-color: #fafafa;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.chat__map {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  position: fixed;
  top: 60px;
  right: 0;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 100;
}
@media screen and (min-width: 1024px) {
  .chat__map {
    top: 0;
    height: 100vh;
    width: calc(100% - 300px);
  }
}
.chat__map .map__map {
  border-radius: 0px;
  margin: 0;
  width: 100%;
  height: 100%;
}
.chat__map.chat__map--active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header {
  background-color: #00bfa5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(20px / 2);
  width: 100%;
}
@media screen and (min-width: 768px) {
  .header {
    padding: 20px;
  }
}
.header .header__logo .logo__a {
  display: flex;
  align-items: center;
}
.header .header__logo .logo__title {
  margin: 0;
  color: #ffffff;
}
.header .header__logo svg {
  margin: 0 10px 0 0;
  height: 30px;
}
.header .header__nav ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.header .header__nav ul li {
  margin: 0 0 0 20px;
  list-style: none;
}
.header .header__nav ul li:first-child {
  margin: 0;
}
.header .header__nav ul a {
  border: 2px #ffffff solid;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #ffffff;
}
@media screen and (min-width: 1024px) {
  .header .header__nav ul a {
    background-color: transparent;
  }
}
.header .header__nav ul a svg {
  margin: 0 5px 0 0;
  height: 18px;
}
.header .header__nav ul a path {
  fill: #ffffff;
}
.header .header__nav ul a:hover, .header .header__nav ul a:active {
  background-color: #00897b;
  border-color: #00897b;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.page .page__header {
  border-bottom: 1px #cccccc solid;
}
.page .page__header .header__title {
  margin: 0;
}
.page .page__intro,
.page .page__copy,
.page .page__btns {
  grid-column: 1/-1;
}
@media screen and (min-width: 1200px) {
  .page .page__intro,
  .page .page__copy,
  .page .page__btns {
    grid-column: 3/-3;
  }
}
@media screen and (min-width: 1024px) {
  .page .page__intro {
    padding: 50px 0;
  }
}
@media screen and (min-width: 1024px) {
  .page .page__content {
    padding-top: 50px;
  }
}
.page .page__content ol li,
.page .page__content ul li {
  margin: 0 0 20px 0;
}
.page .page__btns {
  margin: 0;
}
.page .page__btns li {
  list-style: none;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  height: 100%;
}

body {
  margin: 0;
}

ol,
ul {
  margin: 0 0 0 20px;
  padding: 0;
}
ol li,
ul li {
  margin: 0 0 20px 0;
}
ol li:last-child,
ul li:last-child {
  margin: 0;
}

.wrap {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 20px;
  margin: 0 auto;
  max-width: 1366px;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .wrap {
    padding: 0 50px;
  }
}
@media screen and (min-width: 1024px) {
  .wrap {
    grid-gap: 50px;
  }
}
@media screen and (min-width: 1366px) {
  .wrap {
    padding: 0;
  }
}

.section {
  padding: 20px 0;
}
@media screen and (min-width: 768px) {
  .section {
    padding: 50px 0;
  }
}
@media screen and (min-width: 1024px) {
  .section {
    padding: 120px 0;
  }
}
@media screen and (min-width: 1024px) {
  .section.section--short {
    padding: 50px 0;
  }
}
.section.section--teal {
  color: #ffffff;
}
.section.section--teal h1, .section.section--teal .h1,
.section.section--teal h2, .section.section--teal .h2,
.section.section--teal h3, .section.section--teal .h3,
.section.section--teal h4, .section.section--teal .h4,
.section.section--teal h5, .section.section--teal .h4,
.section.section--teal h6, .section.section--teal .h6 {
  color: #ffffff;
}
.section.section--teal {
  background-color: #00bfa5;
}

.rooms {
  border-top: 1px #cccccc solid;
}
.rooms .rooms__wrap {
  grid-gap: 20px;
}
@media screen and (min-width: 768px) {
  .rooms .rooms__wrap {
    grid-gap: 50px;
  }
}
.rooms .rooms__header {
  grid-column: 1/-1;
}
.rooms .rooms__header.rooms__header--search {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  align-items: end;
}
@media screen and (min-width: 768px) {
  .rooms .rooms__header.rooms__header--search {
    grid-gap: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .rooms .rooms__header.rooms__header--search {
    grid-template-columns: 2fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .rooms .rooms__intro {
    max-width: 650px;
  }
}
.rooms .rooms__types {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .rooms .rooms__types {
    margin: 50px 0 0 0;
  }
}
.rooms .rooms__types li {
  list-style: none;
  margin: 0 20px 20px 0;
}
.rooms .rooms__list {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .rooms .rooms__list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .rooms .rooms__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.rooms .rooms__list li {
  margin: 0;
}
.rooms .rooms__footer {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
}
.rooms.rooms--events .rooms__footer .footer__credit {
  display: flex;
  align-items: center;
  font-style: italic;
  color: #b2b2b2;
}
.rooms.rooms--events .rooms__footer svg {
  margin: 0 0 0 5px;
  height: 12px;
}

.divider:after {
  background-color: #ffffff;
  display: block;
  margin: 20px 0;
  width: 40px;
  height: 2px;
  content: "";
}

.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip:after {
  content: attr(data-tooltip);
  -webkit-transition: all 200ms;
  transition: all 200ms;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
          transform: translate(-50%, calc(-100% - 10px));
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 10px;
  position: absolute;
  left: 0;
  top: 0;
  color: #000000;
  pointer-events: none;
  opacity: 0;
}
.tooltip:hover:after {
  pointer-events: all;
  opacity: 1;
}

.i {
  display: grid;
  align-content: center;
  justify-content: center;
  background-color: #00bfa5;
  border-radius: 50px;
  margin: 0 auto 20px auto;
  width: 100px;
  height: 100px;
}
.i svg {
  display: inline-block;
  height: 44px;
}
.i path {
  fill: #ffffff;
}
.i.i--teal-dark {
  background-color: #00897b;
}

html {
  font-family: "Roboto";
  font-size: 62.5%;
  color: #525252;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0 0 20px 0;
  font-weight: 500;
  color: #525252;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  font-weight: 300;
  color: #00bfa5;
}

h1, .h1 {
  font-size: 3rem;
  line-height: 4rem;
}
@media screen and (min-width: 768px) {
  h1, .h1 {
    font-size: 5rem;
    line-height: 6rem;
  }
}

h2, .h2 {
  font-size: 2.5rem;
  line-height: 3.5rem;
}
@media screen and (min-width: 768px) {
  h2, .h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
}

h3, .h3 {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.6rem;
}
@media screen and (min-width: 768px) {
  h3, .h3 {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

h4, .h4 {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #525252;
}

.intro {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #b2b2b2;
}
@media screen and (min-width: 768px) {
  .intro {
    font-size: 2rem;
    line-height: 3rem;
  }
}

p {
  margin: 0 0 15px 0;
}
p:last-child {
  margin: 0;
}

label {
  display: block;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  margin: 0 0 20px 0;
}

input:not([type=checkbox]),
select {
  -webkit-appearance: none;
}

input, select {
  background-color: #ffffff;
  border: 1px #ccc solid;
  border-radius: 4px;
  padding: 15px;
  font-size: 1.4rem;
  line-height: 1.4rem;
  width: 100%;
}
