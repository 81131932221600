@import '../../sass/global.scss';

.addmessage {
	background-color: $c-white;
	border-top: 1px $c-stone solid;
	display: grid;
	grid-template-columns: 16fr 2fr;
	grid-gap: $gutter-s-half;
	align-items: center;
	position: fixed;
	left: 0;
	bottom: 0;
	padding: $gutter-s-half;
	width: 100%;
	height: $chat-form-height;

	@media #{$mq-l} {
		position: absolute;
		height: $chat-form-height-l;
		padding: $gutter-s;
		grid-gap: $gutter-s;
	}

	@media #{$mq-xl} {
		grid-template-columns: 16fr 3fr;
	}

	.addmessage__text {
		position: relative;

		input {
			padding-right: 70px;
		}
	}

	.addmessage__addemoji {
		background-color: transparent;
		position: absolute;
		padding: 0;
		right: 40px;
		width: auto;
	}

	.addmessage__addemoji,
	.addmessage__file {

		svg {
			margin: 0;
			height: 20px;
		}

		path {
			fill: $c-stone;
		}
	}

	.addmessage__addemoji {
		display: none;

		@media #{$mq-l} {
			display: inline-block;
		}
	}

	.addmessage__file {

		label {
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
		}

		input {
			display: none;
		}
	}

	input,
	button {
		padding: $gutter-s-half;
		height: 40px;

		@media #{$mq-l} {
			padding: $gutter-s-half 15px;
			height: 44px;
		}
	}

	.addmessage__btn {
		border-radius: 4px;

		@media #{$mq-l} {
			border-radius: 40px;
		}

		svg {
			margin: 0;

			@media #{$mq-xl} {
				margin: 0 10px 0 0;
			}
		}

		.btn__text {
			display: none;

			@media #{$mq-xl} {
				display: inline-block;
			}
		}
	}
}
