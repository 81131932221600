@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  height: 100%;
}

body {
  margin: 0;
}

ol,
ul {
  margin: 0 0 0 20px;
  padding: 0;
}
ol li,
ul li {
  margin: 0 0 20px 0;
}
ol li:last-child,
ul li:last-child {
  margin: 0;
}

.wrap {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 20px;
  margin: 0 auto;
  max-width: 1366px;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .wrap {
    padding: 0 50px;
  }
}
@media screen and (min-width: 1024px) {
  .wrap {
    grid-gap: 50px;
  }
}
@media screen and (min-width: 1366px) {
  .wrap {
    padding: 0;
  }
}

.section {
  padding: 20px 0;
}
@media screen and (min-width: 768px) {
  .section {
    padding: 50px 0;
  }
}
@media screen and (min-width: 1024px) {
  .section {
    padding: 120px 0;
  }
}
@media screen and (min-width: 1024px) {
  .section.section--short {
    padding: 50px 0;
  }
}
.section.section--teal {
  color: #ffffff;
}
.section.section--teal h1, .section.section--teal .h1,
.section.section--teal h2, .section.section--teal .h2,
.section.section--teal h3, .section.section--teal .h3,
.section.section--teal h4, .section.section--teal .h4,
.section.section--teal h5, .section.section--teal .h4,
.section.section--teal h6, .section.section--teal .h6 {
  color: #ffffff;
}
.section.section--teal {
  background-color: #00bfa5;
}

.rooms {
  border-top: 1px #cccccc solid;
}
.rooms .rooms__wrap {
  grid-gap: 20px;
}
@media screen and (min-width: 768px) {
  .rooms .rooms__wrap {
    grid-gap: 50px;
  }
}
.rooms .rooms__header {
  grid-column: 1/-1;
}
.rooms .rooms__header.rooms__header--search {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  align-items: end;
}
@media screen and (min-width: 768px) {
  .rooms .rooms__header.rooms__header--search {
    grid-gap: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .rooms .rooms__header.rooms__header--search {
    grid-template-columns: 2fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .rooms .rooms__intro {
    max-width: 650px;
  }
}
.rooms .rooms__types {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .rooms .rooms__types {
    margin: 50px 0 0 0;
  }
}
.rooms .rooms__types li {
  list-style: none;
  margin: 0 20px 20px 0;
}
.rooms .rooms__list {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .rooms .rooms__list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .rooms .rooms__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.rooms .rooms__list li {
  margin: 0;
}
.rooms .rooms__footer {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
}
.rooms.rooms--events .rooms__footer .footer__credit {
  display: flex;
  align-items: center;
  font-style: italic;
  color: #b2b2b2;
}
.rooms.rooms--events .rooms__footer svg {
  margin: 0 0 0 5px;
  height: 12px;
}

.divider:after {
  background-color: #ffffff;
  display: block;
  margin: 20px 0;
  width: 40px;
  height: 2px;
  content: "";
}

.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip:after {
  content: attr(data-tooltip);
  transition: all 200ms;
  transform: translate(-50%, calc(-100% - 10px));
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 10px;
  position: absolute;
  left: 0;
  top: 0;
  color: #000000;
  pointer-events: none;
  opacity: 0;
}
.tooltip:hover:after {
  pointer-events: all;
  opacity: 1;
}

.i {
  display: grid;
  align-content: center;
  justify-content: center;
  background-color: #00bfa5;
  border-radius: 50px;
  margin: 0 auto 20px auto;
  width: 100px;
  height: 100px;
}
.i svg {
  display: inline-block;
  height: 44px;
}
.i path {
  fill: #ffffff;
}
.i.i--teal-dark {
  background-color: #00897b;
}

html {
  font-family: "Roboto";
  font-size: 62.5%;
  color: #525252;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0 0 20px 0;
  font-weight: 500;
  color: #525252;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  font-weight: 300;
  color: #00bfa5;
}

h1, .h1 {
  font-size: 3rem;
  line-height: 4rem;
}
@media screen and (min-width: 768px) {
  h1, .h1 {
    font-size: 5rem;
    line-height: 6rem;
  }
}

h2, .h2 {
  font-size: 2.5rem;
  line-height: 3.5rem;
}
@media screen and (min-width: 768px) {
  h2, .h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
}

h3, .h3 {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.6rem;
}
@media screen and (min-width: 768px) {
  h3, .h3 {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

h4, .h4 {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #525252;
}

.intro {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #b2b2b2;
}
@media screen and (min-width: 768px) {
  .intro {
    font-size: 2rem;
    line-height: 3rem;
  }
}

p {
  margin: 0 0 15px 0;
}
p:last-child {
  margin: 0;
}

label {
  display: block;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  margin: 0 0 20px 0;
}

input:not([type=checkbox]),
select {
  -webkit-appearance: none;
}

input, select {
  background-color: #ffffff;
  border: 1px #ccc solid;
  border-radius: 4px;
  padding: 15px;
  font-size: 1.4rem;
  line-height: 1.4rem;
  width: 100%;
}