@import '../../sass/global.scss';

.viewport {
	position: fixed;
	top: $chat-header-height;
	width: 100%;
	height: calc(100% - #{$chat-header-height});
	overflow: hidden;

	@media #{$mq-l} {
		top: 0;
		left: 300px;
		width: calc(100vw - 300px);
		height: calc(100% - #{$chat-form-height});
		margin: 0;
		height: 100%;
	}

	.viewport__chat {
		height: 100%
	}

	.viewport__list {
		padding: 0;
		float: left;
		width: 100%;
		height: calc(100% - #{$chat-header-height});
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		margin: 0;

		@media #{$mq-l} {
			margin: 0;
			height: calc(100vh - #{$chat-form-height-l});
		}

		li {
			display: grid;
			grid-gap: 5px;
			grid-template-columns: 1fr;
			align-items: center;
			list-style: none;
			padding: $gutter-s;
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 2.4rem;
			word-break: break-all;
			width: 100%;
			margin: 0;

			@media #{$mq-m} {
				grid-gap: $gutter-s-half;
			}

			@media #{$mq-l} {
				grid-template-columns: 11fr 1fr;
			}

			.message__time {
				color: $c-rock;
				font-size: 1rem;
				line-height: 1rem;

				@media #{$mq-m} {
					font-size: 1.2rem;
					line-height: 1.2rem;
				}

				@media #{$mq-l} {
					text-align: right;
				}
			}

			img {
				display: block;
				margin: $gutter-s 0 0 0;
				max-width: 100%;
			}

			&:nth-child(odd) {
				background-color: $c-alabaster;
			}
		}
	}
}
