@import '../../sass/global.scss';

.page {

	.page__header {
		border-bottom: 1px $c-stone solid;

		.header__title {
			margin: 0;
		}
	}

	.page__intro,
	.page__copy,
	.page__btns {
		grid-column: 1 / -1;

		@media #{$mq-xl} {
			grid-column: 3 / -3;
		}
	}

	.page__intro {

		@media #{$mq-l} {
			padding: $gutter-m 0;
		}
	}

	.page__content {

		@media #{$mq-l} {
			padding-top: $gutter-m;
		}

		ol,
		ul {

			li {
				margin: 0 0 $gutter-s 0;
			}
		}
	}

	.page__btns {
		margin: 0;

		li {
			list-style: none;
		}
	}
}
