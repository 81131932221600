@import '../../sass/global.scss';

.chatheader {
	border-bottom: 1px $c-smoke solid;
	background-color: $c-white;
	position: fixed;
	top: 0;
	left: 0;
	display: grid;
	align-items: center;
	grid-template-columns: repeat(12, 1fr);
	width: 100%;
	height: $chat-header-height;
	padding: 0 $gutter-s-half;
	z-index: 100;

	@media #{$mq-l} {
		display: none;
	}

	.chatheader__title {
		grid-column: 3 / 10;
		text-align: center;
		margin: 0;
	}

	.chatheader__list {
		grid-column: 10 / -1;
		display: flex;
		justify-content: flex-end;
		margin: 0;
		padding: 0;

		li {
			margin: 0 0 0 $gutter-s-half;
			list-style: none;

			&:first-child {
				margin: 0;
			}
		}
	}
}
