@import '../../sass/global.scss';

.footer {
  border-top: 1px $c-flint solid;
  padding: $gutter-s 0;

  .footer__company {
    grid-column: 1 / -1;

    @media #{$mq-m} {
			display: flex;
			align-items: center;
      grid-column: 1 / 9;
    }

    strong {
      color: $c-teal;
    }
  }

	.footer__copyright {

		@media #{$mq-m} {
			display: flex;
		}
	}

	.footer__logo {
		margin: 0 0 $gutter-s-half 0;
		display: flex;
		align-items: center;
		width: auto;

		@media #{$mq-m} {
			margin: 0 5px 0 0;
		}

		img {
			margin: 0 5px 0 0;
			display: block;
			width: 20px;
			height: 20px;
		}
	}

	.footer__nav {
		display: flex;
		margin: $gutter-s-half 0 0 0;
		padding: 0;
		width: 100%;

		@media #{$mq-m} {
			margin: 0 0 0 $gutter-s;
			width: auto;
		}

		li {
			margin: 0 10px 0 0;
			list-style: none;

			&:last-child {
				margin: 0;
			}
		}
	}

  .footer__credit {
		display: flex;
		align-items: center;
    grid-column: 1 / -1;

    @media #{$mq-m} {
      grid-column: 9 / -1;
      justify-content: flex-end;
    }

    svg {
      vertical-align: middle;
			margin: 0 5px;
      height: 15px;
    }

    path {
      fill: $c-red;
    }

		a {
			margin: 0 0 0 5px;
		}
  }
}
