@import '../../sass/global.scss';

.why {

	.why__wrap {
		grid-gap: $gutter-s;

		@media #{$mq-m} {
			grid-gap: $gutter-m;
		}
	}

  .why__header {
    grid-column: 1 / -1;
    text-align: center;

    @media #{$mq-m} {
      grid-column: 2 / -2;
    }

    @media #{$mq-l} {
      grid-column: 3 / -3;
    }
  }

  .why__intro {
    color: $c-white;
  }

  .why__list {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $gutter-s;
    margin: 0;
    padding: 0;

    @media #{$mq-m} {
      grid-template-columns: repeat(4, 1fr);
    }

    @media #{$mq-l} {
      grid-gap: $gutter-m;
    }

    li {
      list-style: none;
      font-size: 1.4rem;
      line-height: 2.4rem;
      text-align: center;

      @media #{$mq-m} {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }
  }
}
